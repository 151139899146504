<template>
  <div ref="map" id="map"></div>
</template>

<script>
export default {
    props:['location'],
    mounted () {
        const center = this.location
        const map = new window.google.maps.Map(this.$refs.map, {
            scaleControl: false,
            disableDefaultUI: true,
            center: center,
            zoom: 14,
        })
        new window.google.maps.Marker({ map, position: center });
      
       
   
    }
}
</script>

<style lang="scss">
#map {
    width: 100%;
    min-height: 300px;
}
</style>